<template>
  <div class="container">
    <div class="main">
      <div class="header">
        <span class="title"> 用户信息 </span>
        <el-button v-if="!addAdvise" @click="$router.go(-1)" type="primary"
          >返回</el-button
        >
      </div>
      <div class="des">
        <p>
          <span class="label">用户手机号：</span>
          <span class="value">{{ mobile }}</span>
        </p>
        <p>
          <span class="label">用户姓名：</span>
          <span class="value">{{ name }}</span>
        </p>
      </div>
      <el-divider class="divider" />
      <!-- 编辑列表 -->
      <AddDrug
        v-if="addAdvise"
        :userId="userId"
        :nick-name="nickName"
        @changeDispaly="changeDispaly"
        :itemInfo="state.itemInfo"
      />
      <!-- 展示列表 -->
      <CareAdvise
        v-else
        :userId="userId"
        :nick-name="nickName"
        @showEdit="showEdit"
      />
    </div>
  </div>
</template>

<script >
import { reactive, onMounted, toRefs } from '@vue/composition-api';
import AddDrug from './components/AddDrug';
import CareAdvise from './components/CareAdvise';

// 默认的状态
const __sfc_main = {};
__sfc_main.setup = (__props, __ctx) => {
  const state = reactive({
    // id: '',
    // mobile: '', // 手机号
    itemInfo: {},
    addAdvise: false,
    detail: JSON.parse(sessionStorage.getItem('heealth_reminder_item') || '{}') // 服务订单详情
  });
  const {
    addAdvise
  } = toRefs(state);
  const {
    id: userId,
    name,
    mobile,
    nickName
  } = toRefs(state.detail);
  onMounted(async () => {
    // try {
    //   const { mobile = '' } = await queryUserIdentifyByUserId({
    //     userId: userId.value,
    //   });
    //   state.mobile = mobile;
    // } catch (e) {
    //   console.log('error->', e);
    // }
  });

  // 显示编辑添加页面
  const showEdit = (val, data) => {
    state.addAdvise = val;
    state.itemInfo = data;
  };

  // 切换
  const changeDispaly = val => {
    state.addAdvise = val;
  };

  // // 返回
  // const back = () => {
  //   router.replace({
  //     path: '/healthReminder',
  //   });
  // };
  return {
    state,
    addAdvise,
    userId,
    name,
    mobile,
    nickName,
    showEdit,
    changeDispaly
  };
};
__sfc_main.components = Object.assign({
  AddDrug,
  CareAdvise
}, __sfc_main.components);
export default __sfc_main;
</script>
<style rel="stylesheet/scss" lang="scss" scoped>
.container {
  max-height: 100vh;
  padding: 15px;
  box-sizing: border-box;
  .el-descriptions-item__container {
    margin-right: 100px;
  }
  .main {
    position: relative;
    padding: 15px;
    background: #fff;
    border-radius: 4px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title {
      height: 25px;
      line-height: 25px;
      font-size: 18px;
      color: #000;

      &::before {
        content: '';
        position: absolute;
        left: 0;
        border-width: 3px;
        border-style: solid;
        border-color: #17b3a3;
        border-top-width: 20px;
        border-top-color: currentColor;
        color: #17b3a3;
      }
    }

    .des {
      margin-top: 36px;
    }

    ::v-deep .divider {
      border: none;
    }
  }
}
</style>
