<template>
  <div>
    <el-button type="primary" style="margin-bottom: 1rem" @click="addDrug()">
      新增
    </el-button>
    <!-- <el-button
      type="default"
      style="margin-bottom: 1rem"
      @click="batchAddDrug()"
    >
      批量新增【肺模板】
    </el-button> -->
    <el-table
      :data="tableData"
      v-loading="loading"
      class="table"
      border
      style="width: 100%"
      height="calc(100vh - 396px)"
    >
      <el-table-column prop="remindType" label="提醒类型" v-slot="scope">
        {{ tipsTypes[scope.row.remindType] }}
      </el-table-column>
      <el-table-column prop="name" label="提醒事项"></el-table-column>
      <el-table-column prop="target" label="目标量"></el-table-column>
      <el-table-column prop="remindTime" label="提醒日期"></el-table-column>
      <el-table-column width="150" label="提醒时间" v-slot="scope">
        {{ getCustomizeTime(scope.row) }}
      </el-table-column>
      <el-table-column prop="attention" label="注意事项"></el-table-column>
      <el-table-column
        prop="customizeCycleTime"
        label="提醒周期"
        v-slot="scope"
      >
        {{ getCustomizeCycle(scope.row) }}
      </el-table-column>
      <el-table-column prop="startTime" label="开始日期"></el-table-column>
      <el-table-column prop="creatorName" label="创建人"></el-table-column>
      <el-table-column prop="createDate" label="创建日期"></el-table-column>
      <el-table-column label="操作" align="center" fixed="right">
        <template #default="scope">
          <el-button type="text" @click="editTips(scope.row)"> 编辑 </el-button>
          <el-button
            v-if="scope.row.open === '1'"
            type="text"
            @click="openTips(scope.row)"
          >
            开启
          </el-button>
          <el-button
            v-if="scope.row.open === '0'"
            type="text"
            @click="closeTips(scope.row)"
          >
            关闭
          </el-button>
          <el-button type="text" @click="deleteTips(scope.row)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <!-- 分页 -->
      <el-pagination
        background
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="pagination.pageIndex"
        :page-sizes="[10, 20, 30, 40, 50]"
        :page-size="10"
        layout="prev, pager, next"
        :total="pagination.total"
      ></el-pagination>
    </div>
  </div>
</template>

<script>
import {
  queryPage,
  openAndClose,
  deleteHealthRemind,
  // getMedication,
  // medicationOpen,
  // deleteMedication,
} from '@/api/healthReminder';
export default {
  props: ['userId', 'nickName', 'cardId'],
  data() {
    return {
      loading: true,
      nowSize: '',
      afterPage: '',
      tableData: [],
      pagination: {
        pageIndex: 1,
        pageSize: 10,
        pageSizes: [10, 20, 30, 40, 50],
        total: 0,
      },
      customizeCycleTimeMuster: {
        0: '长期',
        7: '一周',
        14: '两周',
        28: '一个月',
      },
      customizeTimeMuster: {
        1: '隔一天',
        7: '隔一周',
      },
      tipsTypes: {
        1: '用药提醒',
        2: '运动提醒',
        3: '监测提醒',
        4: '自定义提醒',
      },
    };
  },
  created() {
    this.getMedication();
  },
  methods: {
    getMedication() {
      const data = {
        userId: this.userId,
        pageIndex: this.pagination.pageIndex,
        pageSize: this.pagination.pageSize,
      };

      queryPage(data)
        .then((res) => {
          console.log(res);
          this.tableData = res.data.list;
          this.pagination.total = res.data.total;

          this.afterPage = Math.floor(
            this.pagination.total / this.pagination.pageSize
          );
          console.log('000 afterPage', this.afterPage);
          //  全部的数据值/页面大小 并且向下去整数
          this.nowSize = this.pagination.total % this.pagination.pageSize;
          console.log('000 this.nowSize', this.nowSize);
          //  全部的数据值除页面大小的余数  最后一页的数据条数
          this.loading = false;
        })
        .catch(() => {
          this.loading = false;
        });
    },
    sizeChange(pageSize) {
      this.pagination.pageSize = pageSize;
      this.getMedication();
    },
    currentChange(pageIndex) {
      this.pagination.pageIndex = pageIndex;
      this.getMedication();
    },
    // 获取提醒时间
    getCustomizeTime(row) {
      const { frequencys, customizeTime } = row;
      if ([1, 7].includes(customizeTime)) {
        return this.customizeTimeMuster[customizeTime];
      }
      if (frequencys.length > 0) {
        return frequencys.map((item) => item.frequency).join('');
      }
      return customizeTime ? `${customizeTime}天` : '';
    },
    // 获取提醒周期
    getCustomizeCycle(row) {
      const { longTerm, customizeCycleTime } = row;
      let customize = '';
      if (longTerm === '1') {
        customize = '长期';
      }
      if (longTerm === '0') {
        customize =
          this.customizeCycleTimeMuster[customizeCycleTime] ||
          `${customizeCycleTime}天`;
      }
      return customize;
    },
    // 开启
    openTips(openRow) {
      console.log('000', openRow);

      this.$confirm('是否开启该提醒？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '放弃',
        type: 'warning',
      })
        .then(() => {
          const data = {
            id: openRow.id,
            open: '0',
          };
          this.loading = true;
          openAndClose(data).then((res) => {
            console.log(res);
            this.getMedication();
          });
          this.$message({
            type: 'success',
            message: '开启成功!',
          });
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            type: 'info',
            message: '放弃开启',
          });
        });
    },
    // 关闭提醒
    closeTips(closeRow) {
      this.$confirm('是否关闭该提醒？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '放弃',
        type: 'warning',
      })
        .then(() => {
          const data = {
            id: closeRow.id,
            open: '1',
          };
          this.loading = true;
          openAndClose(data).then(() => {
            this.getMedication();
          });
          this.$message({
            type: 'success',
            message: '关闭成功!',
          });
        })
        .catch(() => {
          this.loading = false;
          this.$message({
            type: 'info',
            message: '放弃关闭',
          });
        });
    },
    // 删除提醒
    deleteTips(deleteRow) {
      this.$confirm('是否删除该提醒？', '提示', {
        confirmButtonText: '确认',
        cancelButtonText: '放弃',
        type: 'warning',
      })
        .then(() => {
          const data = {
            id: deleteRow.id,
          };
          this.loading = true;
          deleteHealthRemind(data).then((res) => {
            console.log(res);
            if (this.nowSize === 1 && this.afterPage >= 0) {
              this.pagination.pageIndex--;
            }
            this.getMedication();
          });
          this.$message({
            type: 'success',
            message: '删除成功!',
          });
        })
        .catch((error) => {
          console.log(error);
          this.loading = false;
          this.$message({
            type: 'info',
            message: '放弃删除',
          });
        });
    },
    // 编辑提醒
    editTips(data) {
      this.$emit('showEdit', true, data);
    },
    // 新增提醒
    addDrug() {
      this.$emit('showEdit', true);
    },
  },
};
</script>

<style lang="scss" scoprd>
.el-table {
  text-align: center;
}
.pagination {
  display: flex;
  padding: 15px 0;
  justify-content: flex-end;
}
</style>
